@mixin device($breakpoint) {
  @if ($breakpoint == xs) {
    @media (min-width: map-get($breakpoints, xs)) and (max-width: map-get($breakpoints, sm) - 1) {
      @content;
    }
  } @else if ($breakpoint == sm) {
    @media (min-width: map-get($breakpoints, sm)) and (max-width: map-get($breakpoints, md) - 1) {
      @content;
    }
  } @else if ($breakpoint == md) {
    @media (min-width: map-get($breakpoints, md)) and (max-width: map-get($breakpoints, lg) - 1) {
      @content;
    }
  } @else if ($breakpoint == lg) {
    @media (min-width: map-get($breakpoints, lg)) {
      @content;
    }
  }
}

@mixin device-up($breakpoint) {
  @media (min-width: map-get($breakpoints, $breakpoint)) {
    @content;
  }
}
