@import '~assets/styles/common';

.textbox-container {
  min-height: 66px;
  border: solid 1px $color-main-secondary;
  background-color: $color-main-background;
  padding: 8px 10px 0px 14px;
  margin-bottom: 8px;
  position: relative;

  @include device(xs) {
    margin-bottom: 6px;
  }

  label,
  span {
    font-family: $font-body;
    font-size: $font-size-input-label;
    color: $color-main-input-label;
    text-transform: uppercase;
  }

  span.error {
    text-transform: none;
    color: $color-main-accent;
    float: right;
    padding-top: 3px;
    text-align: right;
    max-width: 80%;

    @include device(xs) {
      max-width: 60%;
    }
  }

  input {
    border: 0;
    width: 100%;
    font-size: $font-size-input;
    padding: 3px 0px 13px 0px;
    color: $color-main-text;
    background-color: $color-main-background;
  }

  input:hover,
  input:active,
  input:focus,
  label:focus {
    outline: none !important;
    border: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
  }

  input[type='number']::-webkit-outer-spin-button,
  input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
}
