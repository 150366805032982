@import '~assets/styles/common';

.computing-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  width: 100%;
  max-width: 568px;
  margin: auto;

  .computing-gears {
    position: relative;
    text-align: center;
    margin-top: 15vh;
    min-height: 280px;

    @include device(xs) {
      margin-top: 10vh;
      min-height: 250px;
    }

    .gear {
      position: absolute;

      svg {
        fill: $fill-accent;
      }
    }

    .gear-1 {
      top: 0;
      left: 0;
      right: 0;
      animation: reverse-rotation 8s infinite linear;
    }

    .gear-2 {
      top: 80px;
      left: 265px;
      animation: rotation 8s infinite linear;

      @include device(xs) {
        top: 80px;
        left: 125px;
      }
    }

    .gear-3 {
      top: 144px;
      left: 205px;
      animation: reverse-rotation 8s infinite linear;

      @include device(xs) {
        top: 138px;
        left: 64px;
      }
    }
  }

  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }

  @keyframes reverse-rotation {
    from {
      transform: rotate(359deg);
    }
    to {
      transform: rotate(0deg);
    }
  }

  #slides {
    position: relative;
    padding: 0px;
    margin: 0px;
    list-style-type: none;
  }

  .slide {
    font-family: $font-heading;
    font-size: $font-loading-text;
    line-height: $line-height-xxl;
    color: $color-main-text;
    font-weight: bold;
    text-align: center;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    opacity: 0;

    -webkit-transition: opacity 1s;
    -moz-transition: opacity 1s;
    -o-transition: opacity 1s;
    transition: opacity 1s;

    @include device(xs) {
      font-size: $font-mobile-loading-text;
      line-height: $line-height-lg;
      padding: 0 15px;
    }
  }

  .logos {
    margin-top: 48px;
    text-align: center;

    svg:first-child {
      margin-right: 44px;
    }

    svg {
      height: 36px;
      width: 172px;

      &.varma {
        margin-top: 10px;
        height: 20px;
      }

      @include device(xs) {
        height: 24px;
        width: 115px;

        &.varma {
          height: 12px;
        }
      }
    }
  }

  .showing {
    opacity: 1;
  }

  .dots-wrap {
    text-align: center;
    padding-inline-start: 0px;

    @include device(xs) {
      margin-bottom: 10px;
    }
  }

  .dot {
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: $color-main-text;
    display: inline-block;
    text-align: center;
    margin-left: 2px;
    margin-right: 2px;
    vertical-align: middle;
    cursor: pointer;

    -webkit-transition: height 1s, width 1s;
    -moz-transition: height 1s, width 1s;
    -o-transition: height 1s, width 1s;
    transition: height 1s, width 1s;

    @include device(xs) {
      width: 5px;
      height: 5px;
    }
  }

  .dot-active {
    width: 14px;
    height: 14px;
    background-color: $color-main-accent;

    @include device(xs) {
      width: 10px;
      height: 10px;
    }
  }
}

.feedback-computing {
  .computing-container {
    .computing-gears {
      margin-top: 10vh;
      min-height: 160px;

      .gear {
        svg {
          width: 48px;
          height: 51px;
        }
      }

      .gear-2 {
        top: 50px;
        left: 131px;
      }

      .gear-3 {
        top: 85px;
        left: 92px;
      }
    }

    .slide {
      font-size: $font-mobile-loading-text;
      padding: 0 15px;
    }

    .logos {
      margin-top: 80px;

      svg {
        margin-left: 44px;
        margin-right: 44px;
      }
    }
  }
}
