@import url("https://fonts.googleapis.com/css?family=Playfair+Display:400,400i,700,700i,900,900i&display=swap");
@import url("https://fonts.googleapis.com/css?family=Lato:300,400,900&display=swap");

@font-face {
  font-family: TelegrafRegular;
  src: url("../fonts/PPTelegraf-Regular.woff2");
  src: url("../fonts/PPTelegraf-Regular.eot");
  src: url("../fonts/PPTelegraf-Regular.woff");
}

@font-face {
  font-family: TelegrafSemiBold;
  src: url("../fonts/PPTelegraf-SemiBold.woff2");
  src: url("../fonts/PPTelegraf-SemiBold.eot");
  src: url("../fonts/PPTelegraf-SemiBold.woff");
}
