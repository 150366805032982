$color-white: #ffffff;
$color-denim-1: #112530;
$color-petrol-1: #2B5266;
$color-petrol-2: #6B8694;
$color-champagne-1: #F9EECE;
$color-cognac-1: #CB7434;
$color-cognac-2: #DB9E71;

:root {
  --main-text-color: #{$color-denim-1};
  --main-secondary-color: #{$color-petrol-1};
  --main-input-label-color: #{$color-denim-1};
  --main-background-color: #{$color-white};
  --main-accent-color: #{$color-petrol-1};
  --main-accent-color-2: #{$color-cognac-1};
  --main-accent-text-color: #{$color-white};
  --alternate-text-color: #{$color-white};
  --alternate-background-color: #{$color-denim-1};
}

$breakpoints: (
  xs: 0px,
  sm: 768px,
  md: 1120px,
  lg: 1480px,
);

$font-sizes: (
  h1: 80px,
  h2: 41px,
  h3: 42px,
  h4: 32px,
  h5: 26px,
  h6: 21px,
  body1: 18px,
  body2: 16px,
  body3: 14px,
  body4: 12px,
  button: 15px,
  link: 14px,
);

$line-heights: (
  h1: 78px,
  h2: 46px,
  h3: 44px,
  h4: 40px,
  h5: 34px,
  h6: 28px,
  body1: 26px,
  body2: 22px,
  body3: 19px,
  body4: 18px,
  button: 20px,
  link: 18px,
);

$max-page-width-desktop: 592px;
$max-page-width-mobile: 320px;

$font-body: "TelegrafRegular";
$font-heading: "TelegrafSemiBold";

$font-size-app-title: 48px;
$font-size-page-title: 48px;
$font-size-page-title-benefits: 60px;
$font-size-p: 20px;
$font-size-p-benefits: 18px;
$font-size-p-li: 16px;
$font-size-p-sub: 12px;
$font-size-p-contact: 16px;
$font-size-result-main: 16px;
$font-size-error-foo: 16px;
$font-size-p-our-company: 14px;
$font-size-input-label: 10px;
$font-size-input: 21px;
$font-size-button: 24px;
$font-size-submit-button: 20px;
$font-size-result-submit-button: 16px;
$font-size-main-powered-by: 16px;
$font-size-mobile-error-foo: 14px;
$font-size-footer-powered-by: 16px;
$font-size-company-name: 42px;
$font-size-tiny: 10px;
$font-header: 33px;
$font-big-score: 40px;
$font-circle-score: 22px;
$font-result-content: 18px;
$font-result-sub: 12px;
$font-loading-text: 24px;

$font-size-mobile-app-title: 30px;
$font-size-mobile-page-title: 36px;
$font-size-mobile-page-title-benefits: 32px;
$font-size-mobile-page-title-contact: 26px;
$font-size-mobile-p: 14px;
$font-size-mobile-p-small: 12px;
$font-size-mobile-p-sub: 9px;
$font-size-mobile-p-terms: 9px;
$font-size-mobile-input-label: 10px;
$font-size-mobile-input: 21px;
$font-size-mobile-button: 24px;
$font-circle-score-mobile: 16px;
$font-size-mobile-submit-button: 16px;
$font-size-mobile-main-powered-by: 12px;
$font-size-mobile-footer-powered-by: 10px;
$font-size-mobile-company-name: 26px;
$font-size-mobile-p-sub-start: 11px;
$font-size-mobile-feedback-header: 16px;
$font-mobile-header: 24px;
$font-mobile-big-score: 26px;
$font-mobile-percent-score: 24px;
$font-mobile-result-content: 12px;
$font-mobile-result-sub: 11px;
$font-mobile-loading-text: 18px;
$font-mobile-tiny: 9px;

$line-height-xs: 18px;
$line-height-sm: 19px;
$line-height-md: 22px;
$line-height-lg: 26px;
$line-height-xl: 28px;
$line-height-xxl: 32px;

$color-main-text: var(--main-text-color);
$color-main-secondary: var(--main-secondary-color);
$color-main-input-label: var(--main-input-label-color);
$color-main-background: var(--main-background-color);
$color-main-accent: var(--main-accent-color);
$color-main-accent-text: var(--main-accent-text-color);
$color-alternate-text: var(--alternate-text-color);
$color-alternate-background: var(--alternate-background-color);

$fill-main: var(--main-text-color);
$fill-alternate: var(--alternate-text-color);
$fill-secondary: var(--main-secondary-color);
$fill-accent: var(--main-accent-color);
$fill-accent-2: var(--main-accent-color-2);
