@import "~assets/styles/common";

.submit-button-container {
  button {
    color: $color-main-accent-text;
    font-family: $font-body;
    font-size: $font-size-button;
    font-weight: 900;
    width: 100%;
    height: 66px;
    background-color: $color-main-accent;
    border: none;
    cursor: pointer;
  }

  button:hover,
  button:active,
  button:focus {
    outline: none !important;
    border: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
  }
}

.result-apply-loan,
.result-submit {
  .submit-button-container {
    display: flex;

    button {
      font-size: $font-size-result-submit-button;
      height: 45px;
      width: 175px;
      margin: auto;

      @include device(xs) {
        font-size: $font-size-mobile-submit-button;
        height: 44px;
        width: 218px;
      }
    }
  }
}

.result-apply-loan {
  margin-top: 30px;
  background-color: $color-main-background;
  width: 100%;

  @include device(xs) {
    position: sticky;
    bottom: 0;
  }
}

.result-submit {
  margin-top: 10px;
  margin-bottom: 10px;

  button {
    background-color: $color-main-background;
    color: $color-main-accent;
    border: 1px solid $color-main-accent;
  }

  button:hover,
  button:active,
  button:focus {
    background-color: $color-main-accent;
    color: $color-main-accent-text;
  }
}
