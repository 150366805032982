@import '~assets/styles/common';

.powered-by-container {
  > div {
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }

  a,
  a:hover {
    text-decoration: none;
  }

  svg {
    width: 87px;
    height: 27px;
    fill: $fill-main;

    @include device(xs) {
      width: 58px;
      height: 18px;
    }
  }

  .fundu-site {
    display: flex;
    justify-content: center;
    font-family: $font-heading;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.2px;
    margin-top: 32px;
    color: $color-main-text;

    svg {
      margin-left: 16px;
      fill: none;
      width: 17px;
      height: 16px;
    }
  }
}

.footer-powered-by {
  margin: 70px auto;

  @include device(xs) {
    margin: 20px auto 85px auto;
  }

  svg {
    width: 130px;
    height: 38px;
  }
}

.contact-powered-by {
  margin: 70px auto;

  @include device(xs) {
    margin: 20px auto 70px auto;
  }

  svg {
    width: 130px;
    height: 38px;

    path {
      fill: $fill-alternate;
    }
  }
}


.feedback-powered-by {
  margin: 0px auto 45px auto;

  svg {
    width: 130px;
    height: 38px;
  }
}
