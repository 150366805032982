@import '~assets/styles/common';

.textarea-container {
  min-height: 66px;
  border: solid 1px $color-main-secondary;
  background-color: $color-main-background;
  padding: 8px 10px 5px 14px;
  margin-bottom: 8px;
  position: relative;

  @include device(xs) {
    margin-bottom: 10px;
  }

  label,
  span {
    font-family: $font-body;
    font-size: $font-size-input-label;
    color: $color-main-input-label;
    text-transform: uppercase;
  }

  span.error {
    text-transform: none;
    color: $color-main-accent;
    float: right;
    padding-top: 3px;
    text-align: right;
    max-width: 80%;

    @include device(xs) {
      max-width: 60%;
    }
  }

  textarea {
    border: 0;
    width: 100%;
    resize: none;
    font-size: $font-size-input;
    font-family: $font-body;
    padding: 3px 0px 6px 0px;
    color: $color-main-text;
    background-color: $color-main-background;
  }

  textarea:hover,
  textarea:active,
  textarea:focus,
  label:focus {
    outline: none !important;
    border: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
  }
}
