.h1 {
  font-family: $font-heading;
  font-weight: 600;
  font-size: map-get($font-sizes, h1);
  line-height: map-get($line-heights, h1);
}

.h2 {
  font-family: $font-heading;
  font-weight: 600;
  font-size: map-get($font-sizes, h2);
  line-height: map-get($line-heights, h2);
  letter-spacing: -0.2px;
}

.h3 {
  font-family: $font-body;
  font-weight: 400;
  font-size: map-get($font-sizes, h3);
  line-height: map-get($line-heights, h3);
  letter-spacing: -0.2px;
}

.h4 {
  font-family: $font-body;
  font-weight: 400;
  font-size: map-get($font-sizes, h4);
  line-height: map-get($line-heights, h4);
}

.h5 {
  font-family: $font-heading;
  font-weight: 600;
  font-size: map-get($font-sizes, h5);
  line-height: map-get($line-heights, h5);
  letter-spacing: 0.2px;
}

.h6 {
  font-family: $font-heading;
  font-weight: 600;
  font-size: map-get($font-sizes, h6);
  line-height: map-get($line-heights, h6);
  letter-spacing: 0.2px;
}

.body1 {
  font-family: $font-body;
  font-weight: 400;
  font-size: map-get($font-sizes, body1);
  line-height: map-get($line-heights, body1);
}

.body2 {
  font-family: $font-body;
  font-weight: 400;
  font-size: map-get($font-sizes, body2);
  line-height: map-get($line-heights, body2);
}

.body3 {
  font-family: $font-body;
  font-weight: 400;
  font-size: map-get($font-sizes, body3);
  line-height: map-get($line-heights, body3);
}

.body4 {
  font-family: $font-body;
  font-weight: 400;
  font-size: map-get($font-sizes, body4);
  line-height: map-get($line-heights, body4);
}

.button {
  font-family: $font-heading;
  font-weight: 600;
  font-size: map-get($font-sizes, button);
  line-height: map-get($line-heights, button);
  letter-spacing: 1px;
}

.link,
.tab {
  font-family: $font-heading;
  font-weight: 600;
  font-size: map-get($font-sizes, link);
  line-height: map-get($line-heights, link);
  letter-spacing: 0.2px;
}

@include device-up(md) {
  .md {
    &-h1 {
      font-family: $font-heading;
      font-weight: 600;
      font-size: map-get($font-sizes, h1);
      line-height: map-get($line-heights, h1);
    }

    &-h2 {
      font-family: $font-heading;
      font-weight: 600;
      font-size: map-get($font-sizes, h2);
      line-height: map-get($line-heights, h2);
      letter-spacing: -0.2px;
    }

    &-h3 {
      font-family: $font-body;
      font-weight: 400;
      font-size: map-get($font-sizes, h3);
      line-height: map-get($line-heights, h3);
      letter-spacing: -0.2px;
    }

    &-h4 {
      font-family: $font-body;
      font-weight: 400;
      font-size: map-get($font-sizes, h4);
      line-height: map-get($line-heights, h4);
    }

    &-h5 {
      font-family: $font-heading;
      font-weight: 600;
      font-size: map-get($font-sizes, h5);
      line-height: map-get($line-heights, h5);
      letter-spacing: 0.2px;
    }

    &-h6 {
      font-family: $font-heading;
      font-weight: 600;
      font-size: map-get($font-sizes, h6);
      line-height: map-get($line-heights, h6);
      letter-spacing: 0.2px;
    }

    &-body1 {
      font-family: $font-body;
      font-weight: 400;
      font-size: map-get($font-sizes, body1);
      line-height: map-get($line-heights, body1);
    }

    &-body2 {
      font-family: $font-body;
      font-weight: 400;
      font-size: map-get($font-sizes, body2);
      line-height: map-get($line-heights, body2);
    }

    &-body3 {
      font-family: $font-body;
      font-weight: 400;
      font-size: map-get($font-sizes, body3);
      line-height: map-get($line-heights, body3);
    }

    &-body4 {
      font-family: $font-body;
      font-weight: 400;
      font-size: map-get($font-sizes, body4);
      line-height: map-get($line-heights, body4);
    }

    &-button {
      font-family: $font-heading;
      font-weight: 600;
      font-size: map-get($font-sizes, button);
      line-height: map-get($line-heights, button);
      letter-spacing: 1px;
    }

    &-link,
    &-tab {
      font-family: $font-heading;
      font-weight: 600;
      font-size: map-get($font-sizes, link);
      line-height: map-get($line-heights, link);
      letter-spacing: 0.2px;
    }
  }
}
